<template>
  <read-article
    :article="news"
    share-on-network
    :galleries="galleries"
  />
</template>

<script>
import { externalBySlug } from '@/services/news-service'
import { findGalleryExternalById } from '@/services/gallery-service'

export default {
  name: 'ReadNews',
  data () {
    return {
      news: {},
      galleries: []
    }
  },
  async created () {
    try {
      const data = await externalBySlug(this.$route.params.slug)
      this.news = data
      if (data.newsGallery) {
        const promises = data.newsGallery.map(({ gallery }) => findGalleryExternalById(gallery.id))
        const result = await Promise.all(promises)
        this.galleries = result.map(({ name, slug, galleryPhoto: [gallery] }) => ({ name, slug, photo: gallery.photo.link }))
      }
    } catch (e) {
      this.$handleHttpError(e)
    }
  }
}
</script>

<style scoped>

</style>
