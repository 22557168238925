import api from './api'

const findAll = () => {
  return api.get('gallery')
}

const findGalleryById = (id) => api.get(`gallery/${id}`)

const findGalleryExternalById = (id) => api.get(`gallery/external/${id}`)

export { findAll, findGalleryById, findGalleryExternalById }
